import React, { Component } from 'react';

class Bitcoins extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    return (
      <div className="mt-5 mx-5">
        <b className="text-dark">
          35KtQ9DUVFAjofZZDA9XRfbapsEV1RCbQ8
        </b>
      </div>
    )
  }
}

export default Bitcoins
